<template>
  <div class="globalChart">
    <v-container>
      <v-btn
        v-if="step != 1"
        @click="fetchSixMonthsData"
        class="mb-4 primary-btn"
        dark
        >گزارش عمومی</v-btn
      >
      <v-btn
        v-if="step == 3"
        @click="fetchMonthData(globalMonthDate)"
        class="mb-4 primary-btn ms-3"
        dark
        >گزارش ماهانه</v-btn
      >
      <v-btn
        @click="capture"
        class="mb-4 primary-btn ms-3"
        dark
        :loading="!showTitle"
        :disabled="!loaded"
      >
        دریافت عکس نمودار‌ها</v-btn
      >
      <a v-show="false" class="btn btn-primary" id="download">Download</a>

      <div id="capture">
        <v-row>
          <v-col cols="12" sm="12" md="7" lg="7">
            <v-card>
              <v-card-text>
                <div v-if="showTitle">
                  <p style="text-align: right; direction: rtl" v-if="step == 1">
                    جهت مشاهده جزئیات روی یک ماه کلیک کنید
                  </p>
                  <p style="text-align: right; direction: rtl" v-if="step == 2">
                    جهت مشاهده جزئیات روی یک روز از ماه کلیک کنید
                  </p>
                  <p style="text-align: right; direction: rtl" v-if="step == 3">
                    جهت مشاهده گزارش هر شیفت روی آن کلیک کنید
                  </p>
                </div>
                <bar-chart
                  v-if="loaded"
                  :chartdata="dataBar"
                  :options="optionsBar"
                >
                </bar-chart>
                <div v-else class="text-center pa-5">
                  <v-progress-circular
                    :size="70"
                    :width="7"
                    color="primary"
                    indeterminate
                  ></v-progress-circular>
                  <h6 class="mt-8 primary--text text--darken-1">
                    در حال بارگذاری...
                  </h6>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="12" md="5" lg="5" >
            <v-row class="pies">
              <v-col cols="12" sm="12" md="6" lg="6">
                <v-card>
                  <!-- <v-card-title class="text-center"
                    ></v-card-title
                  > -->
                  <v-card-text>
                    <pie-chart
                      v-if="loaded"
                      :chartdata="dataPieOne"
                      :options="optionsPieOne"
                    >
                    </pie-chart>
                    <div v-else class="text-center pa-5">
                      <v-progress-circular
                        :size="70"
                        :width="7"
                        color="primary"
                        indeterminate
                      ></v-progress-circular>
                      <h6 class="mt-8 primary--text text--darken-1">
                        در حال بارگذاری...
                      </h6>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" sm="12" md="6" lg="6">
                <v-card>
                  <!-- <v-card-title class="text-center"
                    ></v-card-title
                  > -->
                  <v-card-text>
                    <pie-chart
                      v-if="loaded"
                      :chartdata="dataPieTwo"
                      :options="optionsPieTwo"
                    >
                    </pie-chart>
                    <div v-else class="text-center pa-5">
                      <v-progress-circular
                        :size="70"
                        :width="7"
                        color="primary"
                        indeterminate
                      ></v-progress-circular>
                      <h6 class="mt-8 primary--text text--darken-1">
                        در حال بارگذاری...
                      </h6>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
      <v-row class="tables">
        <v-col cols="12" sm="12" md="6" lg="6">
          <v-card>
            <v-card-title class="text-center"
              >درآمد به ازای درمانگاه</v-card-title
            >
            <v-card-text>
              <b-table
                responsive
                show-empty
                :fields="tableFields1"
                :items="tableItems1"
                empty-text="رکوردی برای نمایش وجود ندارد"
                striped
                :busy="!loaded"
              >
                <template v-slot:head()="data">
                  <div
                    class="table-header"
                    style="text-align: center; vertical-align: middle"
                  >
                    {{ data.label }}
                  </div>
                </template>
                <template v-slot:cell()="data">
                  <div style="text-align: center; vertical-align: middle">
                    {{ data.value }}
                  </div>
                </template>
                <template v-slot:cell(index)="data">
                  <div style="text-align: center; vertical-align: middle">
                    {{ data.index + 1 }}
                  </div>
                </template>
                <div slot="table-busy" class="text-center text-primary my-2 pa-5">
                  <v-progress-circular
                    indeterminate
                    color="primary"
                    class="align-middle"
                  ></v-progress-circular>
                  <h6 class="mt-8 primary--text text--darken-1">
                    در حال بارگذاری...
                  </h6>
                </div>
              </b-table>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col>
          <v-card>
            <v-card-title class="text-center"
              >تعداد خدمات به ازای درمانگاه</v-card-title
            >
            <v-card-text>
              <b-table
                responsive
                show-empty
                :fields="tableFields2"
                :items="tableItems2"
                empty-text="رکوردی برای نمایش وجود ندارد"
                striped
                :busy="!loaded"
              >
                <template v-slot:head()="data">
                  <div
                    class="table-header"
                    style="text-align: center; vertical-align: middle"
                  >
                    {{ data.label }}
                  </div>
                </template>
                <template v-slot:cell()="data">
                  <div style="text-align: center; vertical-align: middle">
                    {{ data.value }}
                  </div>
                </template>
                <template v-slot:cell(index)="data">
                  <div style="text-align: center; vertical-align: middle">
                    {{ data.index + 1 }}
                  </div>
                </template>
                <div slot="table-busy" class="text-center text-primary my-2 pa-5">
                  <v-progress-circular
                    indeterminate
                    color="primary"
                    class="align-middle"
                  ></v-progress-circular>
                  <h6 class="mt-8 primary--text text--darken-1">
                    در حال بارگذاری...
                  </h6>
                </div>
              </b-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import BarChart from "/src/components/charts/BarExample.vue";
import PieChart from "/src/components/charts/PieExample.vue";
import html2canvas from "html2canvas";
export default {
  components: {
    BarChart,
    PieChart,
  },
  data() {
    return {
      loaded: false,
      step: 1,
      globalMonthDate: "",
      globalDayDate: "",
      globalColor: "34,129,170",
      dataBar: {
        labels: [],
        datasets: [
          {
            label: "درآمد (میلیون تومان)",
            backgroundColor: [],
            data: [],
          },
        ],
      },
      optionsBar: {
        title: {
          display: true,
          fontSize: 16,
          position: "top",
          fontFamily: "projectMainFont",
          text: "",
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: "میلیون تومان",
                fontFamily: "projectMainFont",
              },
              ticks: {
                beginAtZero: true,
                fontFamily: "projectMainFont",
              },
              stacked: true,
            },
          ],
          xAxes: [
            {
              stacked: true,
              ticks: {
                fontFamily: "projectMainFont",
              },
            },
          ],
        },
        legend: {
          display: true,
          position: "bottom",
          labels: {
            fontFamily: "projectMainFont",
          },
        },
        tooltips: {
          titleFontFamily: "projectMainFont",
          bodyFontFamily: "projectMainFont",
        },
        onClick: this.refreshChart,
      },

      dataPieOne: {
        labels: [],
        datasets: [
          {
            label: "درآمد (میلیون تومان)",
            backgroundColor: [
              "#9f9fd1", //درمانگاه عمومی
              "#3ea394", //درمانگاه عمومی
              "#9DCEE2", //دندانپزشکی
              "#f07167", //آزمایشگاه
              "#ee9b00", //خدمات در منزل
              "#ae2012", //خدمات لغو شده
            ],
            data: [],
          },
        ],
      },
      tableItems1: [],
      tableFields1: [
        { key: "index", label: "#" },
        { key: "name", label: "اسم درمانگاه" },
        { key: "data", label: "درآمد" },
      ],
      optionsPieOne: {
        title: {
          display: true,
          fontSize: 12,
          position: "top",
          fontFamily: "projectMainFont",
          text: "",
        },
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          usePointStyle: true,
          position: "bottom",
          labels: {
            fontFamily: "projectMainFont",
          },
        },
        tooltips: {
          titleFontFamily: "projectMainFont",
          bodyFontFamily: "projectMainFont",
        },
      },
      monthDic: [
        "فروردین",
        "اردیبهشت",
        "خرداد",
        "تیر",
        "مرداد",
        "شهریور",
        "مهر",
        "آبان",
        "آذر",
        "دی",
        "بهمن",
        "اسفند",
      ],
      dataPieTwo: {
        labels: [],
        datasets: [
          {
            label: "درآمد (میلیون تومان)",
            backgroundColor: [
              "#9f9fd1",
              "#3ea394",
              "#9DCEE2",
              "#f07167",
              "#ee9b00",
              "#ae2012",
            ],
            data: [],
          },
        ],
      },
      tableItems2: [],
      tableFields2: [
        { key: "index", label: "#" },
        { key: "name", label: "اسم درمانگاه" },
        { key: "data", label: "تعداد خدمات" },
      ],
      optionsPieTwo: {
        title: {
          display: true,
          fontSize: 12,
          position: "top",
          fontFamily: "projectMainFont",
          text: "",
        },
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          usePointStyle: true,
          position: "bottom",
          labels: {
            fontFamily: "projectMainFont",
          },
        },
        tooltips: {
          titleFontFamily: "projectMainFont",
          bodyFontFamily: "projectMainFont",
        },
      },
      showTitle: true,
      picName: "گزارش ۶ ماه گذشته",
    };
  },
  methods: {
    capture() {
      this.showTitle = false;
      setTimeout(() => {
        html2canvas(document.querySelector("#capture"))
          .then((canvas) => {
            var button = document.getElementById("download");
            button.setAttribute("href", canvas.toDataURL("image/png", 1));
            button.setAttribute("download", this.picName);
          })
          .then(() => {
            var button = document.getElementById("download");
            button.click();
            setTimeout(() => {
              this.showTitle = true;
            }, 500);
          });
      }, 500);
    },
    refreshChart(evt, item) {
      var label = item[0]["_model"].label;
      switch (this.step) {
        case 1:
          this.fetchMonthData(label);
          break;
        case 2:
          this.fetchDayData(label);
          break;
        case 3:
          this.gotoShift(item[0]["_index"]);
          break;
        default:
          break;
      }
    },
    fetchSixMonthsData() {
      this.picName = "گزارش ۶ ماه گذشته";
      this.loaded = false;
      this.step = 1;
      this.$http
        .post(
          this.baseUrl + "/clinic/reports/totalReportPerClinic",
          {},
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            this.optionsBar.title.text = "نمودار 6 ماه گذشته";
            this.dataBar = res.data.monthlyIncome;
            this.optionsPieOne.title.text = "درآمد 6 ماه گذشته درمانگاه‌ها";
            this.dataPieOne = res.data.perClinicIncome;

            this.tableItems1 = this.dataPieOne.labels.map((label, index) => {
              return {
                name: label,
                data: this.dataPieOne.datasets[0].data[index],
              };
            });
            this.optionsPieTwo.title.text =
              "تعداد خدمات 6 ماه گذشته درمانگاه‌ها";
            this.dataPieTwo = res.data.perClinicCount;
            this.tableItems2 = this.dataPieTwo.labels.map((label, index) => {
              return {
                name: label,
                data: this.dataPieTwo.datasets[0].data[index],
              };
            });

            this.loaded = true;
          } else {
            this.toast(
              "خطایی در سامانه رخ داده است، لطفا دوباره تلاش نمایید",
              "error"
            );
          }
        })
        .catch((err) => {
          console.log(err);
          this.toast("خطا: " + err.response.data, "error");
        });
    },

    fetchMonthData(date) {
      this.loaded = false;
      this.picName =
        "گزارش ماه " +
        this.monthDic[Number(date.split("/")[1] - 1)] +
        " از سال " +
        date.split("/")[0];
      this.globalMonthDate = date;
      this.$http
        .post(
          this.baseUrl + "/clinic/reports/monthlyReportPerClinic",
          {
            year: date.split("/")[0],
            month: date.split("/")[1],
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            this.optionsBar.title.text =
              "گزارش ماه " +
              this.monthDic[Number(date.split("/")[1] - 1)] +
              " از سال " +
              date.split("/")[0];
            this.dataBar = res.data.monthlyIncome;
            this.optionsPieOne.title.text = "درآمد ماهانه درمانگاه‌ها";
            this.dataPieOne = res.data.perClinicIncome;
            this.tableItems1 = this.dataPieOne.labels.map((label, index) => {
              return {
                name: label,
                data: this.dataPieOne.datasets[0].data[index],
              };
            });
            this.optionsPieTwo.title.text = "تعداد خدمات ماهانه درمانگاه‌ها";
            this.dataPieTwo = res.data.perClinicCount;
            this.tableItems2 = this.dataPieTwo.labels.map((label, index) => {
              +this.monthDic[Number(date.split("/")[1] - 1)] +
                " سال " +
                date.split("/")[0];
              return {
                name: label,
                data: this.dataPieTwo.datasets[0].data[index],
              };
            });
            this.step = 2;
            this.loaded = true;
          } else {
            this.toast(
              "خطایی در سامانه رخ داده است، لطفا دوباره تلاش نمایید",
              "error"
            );
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
        });
    },

    fetchDayData(date) {
      this.loaded = false;
      this.globalDayDate = this.globalMonthDate + "/" + date;
      this.picName = "گزارش شیفت‌های تاریخ " + this.globalDayDate;
      this.$http
        .post(
          this.baseUrl + "/clinic/reports/dayReportPerClinic",
          {
            date: this.globalDayDate,
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            this.optionsBar.title.text =
              "گزارش شیفت‌های تاریخ " + this.globalDayDate;
            this.dataBar = res.data.monthlyIncome;
            this.optionsPieOne.title.text = "درآمد روزانه درمانگاه‌ها";
            this.dataPieOne = res.data.perClinicIncome;
            this.tableItems1 = this.dataPieOne.labels.map((label, index) => {
              return {
                name: label,
                data: this.dataPieOne.datasets[0].data[index],
              };
            });
            this.optionsPieTwo.title.text = "تعداد خدمات روزانه درمانگاه‌ها";
            this.dataPieTwo = res.data.perClinicCount;
            this.tableItems2 = this.dataPieTwo.labels.map((label, index) => {
              return {
                name: label,
                data: this.dataPieTwo.datasets[0].data[index],
              };
            });
            this.step = 3;
            this.loaded = true;
          } else {
            this.toast(
              "خطایی در سامانه رخ داده است، لطفا دوباره تلاش نمایید",
              "error"
            );
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
        });
    },

    gotoShift(index) {
      //this.$router.push('/admin/shiftReport/'+this.dataBar.datasets[0].shiftIds[index])
      let link =
        "/admin/shiftReport/" + this.dataBar.datasets[0].shiftIds[index];
      window.open(link, "_blank");
    },
  },
  mounted() {
    this.fetchSixMonthsData();
  },
};
</script>
